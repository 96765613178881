// SHOP BY BRAND MAGEPLAZA
// slider brand home page
.block-brand-feature {
  margin-top: 5%;
  //max-width: 100vw;
  max-width: 100vw;
  margin-bottom: 0;
  .block-title {
    > strong {
      font-size: $font-size__xl;
      text-transform: uppercase;
      font-weight: $font-weight__bold;
    }
    .subtitle {
      font-size: $font-size__base;
      margin-top: 20px;
      margin-bottom: 60px;
      text-align: center;
      text-transform: initial;
      font-weight: $font-weight__regular;
    }
  }
  .block-content {
    max-width: 100%;
    .owl-carousel {
      max-width: 100%;
      width: calc(100% - 80px) !important;
    }
  }
  .brand-link {
    //display: block;
    //margin: 40px auto;
    text-align: center;
    color: $color-black;
    font-size: $font-size__s;
    font-weight: $font-weight__light;
    margin-left: 20px;
    //&:hover, &:active {
    //  color: $color-white;
    //  background-color: $color-black;
    //}
  }
}
.owl-carousel {
  position: relative;
  .owl-item img.brand-image {
    width: auto;
    margin: auto;
  }
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    button {
      position: absolute;
      top: -25px;
      > span {
        display: none;
      }
      &.owl-prev {
        background-image: url("../images/icons/left-arrow.svg") !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        width: 26px;
        height: 25px;
        left: -35px;
      }
      &.owl-next {
        background-image: url("../images/icons/right-arrow.svg") !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        width: 26px;
        height: 25px;
        right: -35px;
      }
    }
  }
}
// pagina brand list
.mpbrand-index-index {
  .autocomplete-suggestions {
    box-shadow: none;
    border-color: $color-black;
    .shopbybrand-product-name {
      font-size: $font-size__s;
    }
    .shopbybrand-product-des {
      display: none;
    }
    .shopbybrand-suggestion-left {
      display: none;
    }
  }
  .block-brand-search {
    background-color: transparent;
    color: $color-black;
    padding: 0;
    .block-title {
      text-align: left;
      font-size: $font-size__base;
    }
    .block-content .mp-searchbox {
      margin: 0;
      max-width: unset;
      .form-control {
        padding: 10px !important;
        font-size: $font-size__base;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid $color-black;
        border-radius: 0;
      }
    }
    .search-btn {
      height: 100% !important;
      &:before {
        font-size: $font-size__base;
        line-height: normal;
        color: $color-black;
      }
    }
  }
  .block-brand-list {
    ul.brand-options {
      padding-left: 0;
      li {
        border: none;
        font-size: $font-size__base;
        &#mp-first-opt {
          border: none;
          > a {
            padding-left: 0;
          }
        }
        &.active {
          background: transparent;
          color: $color-black;
          text-decoration: underline;
        }
      }
    }
    .brand-mix-container {
      .product-list-container {
        .product-item {
          margin-bottom: 30px;
          .product-item-info {
            width: 100%;
            .name {
              margin-top: 5px;
              > a {
                font-size: $font-size__xs;
              }
            }
          }
          .product-image-wrapper {
            height: fit-content;
          }
          .product-image-photo {
            margin: 0 auto !important;
            position: static;
          }
        }
      }
    }
  }
}
// pagina detail brand
.brand-information {
  background-color: #aaaaaa;
  @include fullpage-width;
  height: 300px;
  .brand-detail {
    margin-left: 40px;
    display: block;
    text-transform: uppercase;
    color: $color-white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 655px;
    .brand.description {
      text-transform: initial;
    }
    .brand.name {
      font-size: $font-size__base;
    }
  }
}
.brand-product-list {
  .toolbar-products {
    margin: 0;
  }
  .product-image-photo {
    margin: 0 auto;
  }
}
@media (max-width: $screen__xl) {
  .brand-product-list {
    .products-grid .product-item .tooltip .tooltiptext {
      left: unset;
      right: -20%;
    }
  }
}
@media (max-width: $screen__m) {
  .brand-information .brand-detail {
    max-width: 450px;
    margin-left: 20px;
  }
  .block-brand-feature {
    .block-title {
      display: flex;
      flex-direction: column;
      .brand-link {
        margin-left: 0;
      }
    }
  }
}
@media (max-width: $screen__s) {
  .brand-mix-container {
    .product-list-container {
      .product-item {
        width: 100% !important;
      }
      .brand-char-title{
        width: 5%;
      }
    }
  }
  .brand-information .brand-detail {
    max-width: 100%;
    margin-left: 0;
  }
}