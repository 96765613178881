//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

$screen__xxs : 0px !default;
$screen__xs  : 0px !default;
$screen__s   : 500px !default;
$screen__m   : 800px !default;
$screen__l   : 1200px !default;
$screen__xl  : 1600px !default;
