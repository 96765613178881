//
//  Product Lists
//  _____________________________________________
.category-no-image-container {
    min-height:80px;
    position: relative;
    .category-image-container-wrapper {
        padding-right: 10px;
        padding-top: 20px;
        .page-title-wrapper h1 {
            font-size: $font-size__l;
            text-transform: uppercase;
            letter-spacing: 1.69px;
            font-weight: $font-weight__regular;
        }

    }
}
.product {
    &-items {
        //@extend .abs-reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-item {
        //@extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-flex;
            width: 48.5%;
            margin-right: 3%;
            margin-bottom: 30px;

            &:nth-child(2n){
                margin-right: 0;
            }
        }

        &-photo{
            display: block;
        }

        &-details{
            //margin: 0px 10px;

            .price-container{
                span{
                    text-transform: uppercase;
                    line-height: 1.4;

                    &:first-child{
                        margin-right: 6px;
                    }
                }

                .retail-price{
                    display: flex;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    font-size: $font-size__xs;
                }

                .tooltip {
                    font-size: 11px;
                    border-radius: 100%;
                    border: 1px solid black;
                    padding: 2px 5.5px;
                    margin-left: 20px;
                    position: relative;
                    width: 5px;
                    height: 12px;

                    .tooltiptext {
                        visibility: hidden;
                        //width: 200px;
                        width: 135px;
                        background-color: $color-white;
                        color: $color-black;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px 5px;
                        position: absolute;
                        z-index: 1;
                        top: 125%;
                        //right: 20%;
                        right: -60%;
                        //margin-left: -100px;
                        opacity: 0;
                        transition: opacity 0.3s;
                        border: 1px solid $color-black;
                        font-size: $font-size__s;
                        text-transform: unset;
                    }
                }
                .tooltip:hover .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: 24px 0 12px;
            word-wrap: break-word;
            hyphens: auto;
            font-size: $font-size__base;

            .brand, .product-item-link, .product-sku{
                font-size: $font-size__mobile;
                text-transform: uppercase;
            }

            .brand{
                font-weight: $font-weight__bold;
                margin-right: 15px;
            }

            .separator{
                margin: 0 4px;
            }
        }

        &-size{
            font-size: $font-size__mobile;
            margin-bottom: 12px;
            .label {
                font-size: $font-size__s;
                display: inline-block;
                margin-right: 5px;
            }
            .single-size{
                margin-right: 6px;
            }
        }
        .show-price-link {
            font-size: $font-size__mobile;
            text-transform: uppercase;
            border: 1px solid $color-black;
            padding: 5px 10px;
            display: block;
            width: fit-content;
            width: -moz-fit-content;
            text-align: center;
        }
        &-info {
            max-width: 100%;
            width: 152px;

            .page-products & {
                width: 100%;
            }

            
        }

        &-actions {
            display: none;

          .actions-primary {
            display: none;
          }

          .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: $indent__s 0 $indent__m;

            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
            font-weight: $font-weight__bold;
            font-size: $font-size__mobile;
        }

        .normal-price{
            font-size: $font-size__xs;
            span{
                &:last-child{
                    text-decoration: line-through;
                }
            }
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.column.main {
    .product {
        &-items {

        }

        &-item {

        }
    }

}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

@include min-screen($screen__xs) {

    .category-no-image-container {
        .category-image-container-wrapper {
            .page-title-wrapper h1 {
                padding-left: 15px;
            }

        }
    }
}


@include min-screen($screen__s) {

    .product-item-details .price-container .tooltip {
        margin-left: 35px;
        .tooltiptext {
            width: 200px;
            right: 20%;
        }
    }
}
@include min-screen($screen__m){
    .product {
        &-item {
            .products-grid & {

                &:nth-child(2n){
                    margin-right: 3.5%;
                }

                margin-bottom: 50px;
                width: 31%;
                margin-right: 3.5%;

                &:nth-child(3n){
                    margin-right: 0;
                    .product-item-details .price-container .tooltip .tooltiptext {
                        left: unset;
                        right: -20%;
                    }
                }

            }

            &-name {

                .brand, .product-item-link, .product-sku{
                    font-size: $font-size__base;
                }

            }

            //&-size{
            //    font-size: $font-size__base;
            //}
        }

        &-image-photo{
            margin: unset !important;
        }
    }

}


@include min-screen($screen__l) {

    .category-no-image-container {
        .category-image-container-wrapper {
            .page-title-wrapper h1 {
                margin-left: 35px;
            }

        }
    }

    .product {
        &-item {
            .products-grid & {
                .tooltip {
                    font-size: 11px;
                    border-radius: 100%;
                    border: 1px solid black;
                    padding: 2px 5.5px;
                    margin-left: 35px;
                    position: relative;
                    width: 5px;
                    height: 12px;

                    .tooltiptext {
                        visibility: hidden;
                        width: 200px;
                        background-color: $color-white;
                        color: $color-black;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px 5px;
                        position: absolute;
                        z-index: 1;
                        top: 125%;
                        left: 20%;
                        margin-left: -100px;
                        opacity: 0;
                        transition: opacity 0.3s;
                        border: 1px solid $color-black;
                        font-size: $font-size__s;
                        text-transform: unset;
                    }
                }
            }
        }
    }
}




//
//  Mobile
//  _____________________________________________


/*@include min-screen($screen__xs) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: 48.6%;
        margin-right: 2%;

        &:nth-child(2n){
            margin-right: 0;
        }
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 48.6%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 48.6%;
                margin-right: 2%;

                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 16.6666%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 3 * 32.5%) / 3);
                padding: 0;
                margin-right: 0;
                width: 32.4%;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}*/
