// Stili globali checkbox
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  outline: none;
  border: 2px solid $color-black;
  margin-top: 1px;
  margin-bottom: -2px;
}

input[type="checkbox"]:checked {
  border-color: $color-black;
}

input[type='checkbox']:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  margin: 1px 1px;
}

input[type="checkbox"]:checked:before {
  background: $color-black;
}

// Stili globali radio
input[type='radio'] {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: none;
  border: 2px solid $color-black;
  margin: 0;
  margin-bottom: -2px;
}
input[type='radio']:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  margin: 2px 2px;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: $color-black;
}
input[type="radio"]:checked {
  border-color:$color-black;
}
