//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

// Pagina risultati ricerca
.catalogsearch-result-index {
    .page-title-wrapper {
        .page-title {
            font-size: $font-size__l;
            text-transform: uppercase;
            font-weight: $font-weight__regular;
        }
    }
}

.open-search{
    overflow: hidden;

    .block-search{
        top: 0;
        @include transition(top);

        &:after {
            background: rgba(0, 0, 0, 0.5);
            content: '';
            display: block;
            height: 100%;
            position: fixed;
            right: 0;
            top: 74px;
            width: 100%;
            z-index: -1;
            @include transition(top);
        }
    }

    .page-wrapper{
        position: relative;
        top: 74px;
        @include transition(top);
    }
}

.block-search {
    position: fixed;
    width: 100%;
    height: 74px;
    top: calc(-1 * 74px);
    background-color: $color-white;
    z-index: 4;
    padding: 21px 42px;
    box-sizing: border-box;
    @include transition(all);

    &:after {
        content: '';
        top: 0;
        @include transition(top);
    }

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        /*@include lib-icon-font(
            $_icon-font-content      : $icon-search,
            $_icon-font-size         : 35px,
            $_icon-font-line-height  : 33px,
            $_icon-font-color        : $minicart-icons-color,
            $_icon-font-color-hover  : $minicart-icons-color-hover,
            $_icon-font-color-active : $minicart-icons-color-hover,
            $_icon-font-text-hide    : true
        );
        display: inline-block;
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }*/
        display: none;
    }

    .form.minisearch{
        position: relative;
    }

    .action.search {
        display: inline-block;
        @include lib-button-icon(
                $_icon-font-content   : $icon-search,
                $_icon-font-text-hide : true,
                $_icon-font-color     : $header-icons-color
        );
        @include lib-button-reset();
        padding: $indent__xs 0;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;

        &:focus {
            &:before {
                @include lib-css(color, $color-gray20);
            }
        }
    }

    .control {
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
    }

    input {
        top: 0;
        position: absolute;
        display: inline-block;
        border: unset;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    display: none !important;
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
    }
}

@include max-screen($screen__s) {
    .block-search {
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {

        .label {
            @extend .abs-visually-hidden-desktop;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0 0 25px;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder($form-element-input-placeholder__color);
            margin: 0;
            padding-right: 35px;
            position: static;
            border: 1px solid $color-black;
        }

        .action.search {
            display: inline-block;
            @include lib-button-icon(
                $_icon-font-content   : $icon-search,
                $_icon-font-text-hide : true,
                $_icon-font-color     : $header-icons-color
            );
            @include lib-button-reset();
            padding: $indent__xs 0;
            position: absolute;
            right: 10px;
            top: 0;
            z-index: 1;

            &:focus {
                &:before {
                    @include lib-css(color, $color-gray20);
                }
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}
@include min-screen($screen__l) {
    .catalogsearch-result-index .page-title-wrapper .page-title {
        padding-left: 42px;
    }
}