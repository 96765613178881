//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
    .fieldset {
        > .field:not(.choice) {
            > .label {
                width: auto;
                margin-bottom: 10px;
            }
            > .control {
                width: 100%;
                select {
                    @include style-select;
                }
            }
        }
    }
    .minicart-wrapper {
        float: right;
        > button {
            border: none;
            background: url(../images/icons/cart.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 50px;
            height: 50px;
            display: inline-block;
        }
    }
    .modal-inner-wrap {
        background-color: $color-white;
        max-height: 90% !important;
        .modal-footer {
            text-align: center;
            border-top: 1px solid $color-gray-light5;
        }
    }
    .modal-custom-overlay {
        background: rgba(0, 0, 0, 0.5);
    }
    .opc-estimated-wrapper {
        padding: 18px 0;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
    width: 50%;
    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
        text-transform: uppercase;
        font-weight: $font-weight__bold;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none !important;
    }
    .page-title-wrapper {
        text-align: center;
    }
    .checkout-success {
        text-align: center;
    }
    .actions-toolbar > .primary {
        float: unset;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .opc-wrapper {
        width: 100%;
    }
}
@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    .checkout-index-index {
        .custom-slide {
            left: 2.8rem;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
        width: 50%;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}
