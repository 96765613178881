.pages {
    @include lib-pager();
    font-size: $font-size__base;
    width: 50%;
    flex-grow: 1;
    text-align: left;

    &.unique{
        width: 100%;
        text-align: center;
    }

    .item{
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
    }

    strong.page{
        font-size: $font-size__base;
        text-decoration: underline;
    }

    .action {
        width: 34px;
        border: unset;

        &.previous {
            margin-right: 12px;
            border: unset;
        }

        &.next {
            margin-left: 12px;
            border: unset;
        }
    }
}

@include max-screen($screen__l) {
    .pages{
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }
}
