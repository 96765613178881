$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 8px 0 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    //margin-left: -$layout__width-xs-indent;
    //margin-right: -$layout__width-xs-indent;

    .pages {
        //display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }

    .toolbar-filter{
        display: block;
        background-color: $color-light-gray;
        padding: 20px 0;
        line-height: 32px;
        width: 50%;
        text-align: center;
        text-transform: uppercase;
    }
}

.toolbar-sorter {
    width: 50%;
    flex-grow: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    background-color: $color-light-gray;
    padding: 20px 0;

    .sorter {
        padding: 4px 0 0;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .sorter-label{
        line-height: 32px;
        margin-right: 10px;
    }

    .sorter-options {
        @include style-select();
        width: 180px;
        background-color: $color-light-gray;
    }

    .sorter-action {
        vertical-align: top;
        @include lib-icon-font(
                        $icon-arrow-up,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 32px,
                $_icon-font-color: $header-icons-color,
                $_icon-font-color-hover: $header-icons-color-hover,
                $_icon-font-text-hide: true
        );
    }

    .sorter {
        .sort-desc {
            &:before {
                content: $icon-arrow-down;
            }
        }
    }
}

.modes {
    display: none;
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .toolbar-filter{
        display: none !important;
    }

    .toolbar-sorter{
        background-color: $color-white;
        padding: unset;

        .sorter-options {
            background-color: $color-white;
        }
    }
}




//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
        }
    }

    .toolbar-amount {

    }

    .sorter {

    }

    .modes {
        display: block;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $text__color__muted;
        border: 1px solid $border-color__base;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $text__color__muted,
            $_icon-font-color-hover: $text__color__muted,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                color: $text__color__muted;
                background: darken($toolbar-element-background, 7%);
            }
        }

        &:last-child {
            border-right: 1px solid $border-color__base;
        }

        &.active {
            color: $primary__color__light;
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}

@include max-screen($screen__l) {
    .toolbar-sorter{
        justify-content: center;

        .sorter-label{
            display: none;
        }

        .sorter-options{
            border: unset;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
}
