// PAGINA FAQ
.faq-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.faq {
  &-block {
    flex-basis: 30%;
    border: 1px solid $color-black;
    padding: 10px;
    padding-bottom: 0;
    margin-top: 50px;
    //&:nth-last-child(2) {
    //  flex-basis: 48%;
    //}
    //&:last-child {
    //  flex-basis: 48%;
    //}
  }
  &-title {
    font-weight: $font-weight__bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  &-content {
    margin-bottom: 10px;
    //padding-left: 10px;
    line-height: 1.5;
    background: #eee;
    padding: 15px 22px;
  }
  &-dropdown {
    margin-bottom: 10px;
    position: relative;
    > div {
      //span {
      //  font-weight: $font-weight__bold;
      //}
      @include lib-icon-font(
                      $icon-expand,
              $_icon-font-size         : $font-size__base,
              $_icon-font-line-height  : $checkout-payment-option-title-icon__line-height,
              $_icon-font-color        : $color-black,
              $_icon-font-color-hover  : $checkout-payment-option-title-icon__hover__color,
              $_icon-font-color-active : $checkout-payment-option-title-icon__color,
              $_icon-font-margin       : $checkout-payment-option-title-icon__margin,
              $_icon-font-position     : after
      );
      &:after, &:before {
        font-weight: $font-weight__bold !important;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.active {
      > div {
        @include lib-icon-font(
                        $icon-collapse,
                $_icon-font-size         : $font-size__base,
                $_icon-font-line-height  : $checkout-payment-option-title-icon__line-height,
                $_icon-font-color        : $color-black,
                $_icon-font-color-hover  : $checkout-payment-option-title-icon__hover__color,
                $_icon-font-color-active : $checkout-payment-option-title-icon__color,
                $_icon-font-margin       : $checkout-payment-option-title-icon__margin,
                $_icon-font-position     : after
        );
      }
    }
  }
}
// PAGINA CHI SIAMO
.cms-page-view {
  .page-title-wrapper {
    display: none;
  }
  .cms-title {
    font-size: $font-size__xl;
    font-weight: $font-weight__bold;
    margin-bottom: 20px;
    margin-top: 55px;
    text-transform: uppercase;

  }
  .cms-subtitle {
    font-size: $font-size__base;
    font-weight: $font-weight__bold;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  .img-container {
    text-align: center;
    margin-bottom: 30px;
    > img {
      width: 1200px;
    }
  }
  .cms-footer {
    text-align: center;
    margin: auto;
    margin-top: 50px;
    max-width: 1200px;
    .cms-subtitle {
      font-weight: $font-weight__regular;
    }
    .cms-vp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
}
// PAGINA CONTATTI
.contact-index-index {
  .page-title {
    font-size: $font-size__l;
    font-weight: $font-weight__bold;
    margin: 25px 0;
  }
  .address {
    display: block;
    margin-bottom: 20px;
  }
  form {
    width: 50%;
    float: left;
    .field {
      margin-bottom: 15px;
      &.privacy-check {
        position: relative;
        div.mage-error[generated] {
          position: absolute;
          bottom: -20px;
        }
      }
    }
    .actions-toolbar {
      float: right;
    }
  }
  .store-information {
    float: left;
    padding-left: 15%;
    width: 35%;
    .telephone {
      position: relative;
      margin-bottom: 15px;
      &:before {
        position: absolute;
        content: '';
        background-image: url("../images/icons/whatsapp.svg");
        width: 20px;
        height: 20px;
        display: block;
        background-size: contain;
      }
      span {
        margin-left: 25px;
        display: block;
      }
      &.telegram {
        &:before {
          position: absolute;
          content: '';
          background-image: url("../images/icons/telegram.svg");
          width: 20px;
          height: 20px;
          display: block;
          background-size: contain;
        }
      }
    }
    .mail {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        background-image: url("../images/icons/mail.svg");
        width: 20px;
        height: 20px;
        display: block;
        background-size: contain;
      }
      a {
        margin-left: 25px;
      }
    }
  }
}
// PAGINA CLIENTI
.customer-container {
  margin-top: 30px;
  .customer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .opinion {
      max-width: calc(100% - 315px);
      margin-left: 15px;
      font-size: $font-size__s;
      .customer-title {
        font-size: $font-size__base;
        font-weight: $font-weight__bold;
        font-style: normal;
        position: relative;
        padding-left: 90px;
        line-height: normal;
        margin: 0;
        margin-bottom: 15px;
        &:before {
          content: '';
          display: block;
          background-image: url("../images/icons/review-stars.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 80px;
          height: 15px;
          position: absolute;
          top: 4px;
          left: 0;
        }
      }
    }
    .customer-info {
      max-width: 300px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      > img {
        max-width: 300px;
        max-height: 150px;
        width: 100%;
      }
      .customer-name {
        margin-top: 15px;
        display: block;
        flex-basis: 62%;
        padding-right: 10px;
      }
      .customer-image {
        max-width: 100px;
        margin-top: 15px;
      }
    }
  }
}
// PAGINA 404
.cms-no-route {
  .not-found-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .not-found-text-container, .not-found-image-container {
      display: inline-flex;
      width: 50%;
      flex-direction: column;
      text-align: center;
    }
  }
  .not-found-text-container {
    .not-found-error {
      font-size: 100px;
      font-weight: $font-weight__bold;
      text-transform: uppercase;
      &.grey {
        color: #bdbdbd;
      }
    }
    .not-found-text {
      font-size: 50px;
    }
  }
  .not-found-image-container {
    > img {
      width: 80%;
    }
  }
  a.not-found-cta {
    text-transform: uppercase;
    font-size: $font-size__s;
    background: $color-black;
    color: $color-white;
    margin: auto;
    margin-top: 40px;
    padding: 20px;
    &:hover, &:focus {
      background: $color-white;
      color: $color-black;
    }
  }
}
@media (max-width: 992px) {
  .cms-no-route {
    .not-found-container {
      .not-found-text-container {
        .not-found-error {
          font-size: 40px;
        }
        .not-found-text {
          font-size: 20px;
        }
        width: 100%;
      }
      .not-found-image-container {
        display: none;
      }
    }
  }
}
// PAGINA CLIENTI PREMIUM
.cms-clienti-premium {
  .form-container {
    border: 1px solid $color-black;
    padding: 30px;
    max-width: 1200px;
    margin-top: 40px;
    form {
      .fieldset {
        .container-fields {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          .field {
            width: 49%;
            margin-bottom: 15px;
            .control {
              max-width: 450px;
            }
            .label {
              margin-bottom: 5px;
            }
            &.note {
              display: none;
            }
            &.comment {
              width: 100%;
              .control {
                max-width: 100%;
              }
            }
          }
        }
      }
      .actions-toolbar {
        margin-left: auto;
        .primary {
          float: unset;
          display: block;
          margin: auto;
        }
      }
    }
    .store-information {
      display: none;
    }
  }
}

@media (max-width: $screen__l) {
  .faq {
    &-block {
      flex-basis: 47%;
    }
  }
  .cms-clienti-premium .form-container {
    max-width: 100%;
  }
}

@media (max-width: $screen__m) {
  .faq {
    &-block {
      flex-basis: 100% !important;
      margin-top: 30px;
    }
  }
  .contact-index-index {
    form {
      width: 100%;
    }
    .store-information {
      padding-left: 0;
      width: 100%;
      margin-top: 30px;
    }
  }
  .customer-container {
    .customer {
      .opinion {
        max-width: 100%;
        margin-left: 0;
        margin-top: 15px;
      }
      .customer-info {
        max-width: 100%;
      }
    }
  }
  .cms-clienti-premium .form-container form .fieldset .container-fields {
    .field {
      width: 100%;
      .control {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: $screen__s) {
  .cms-page-view {
    .cms-footer {
      .cms-vp {
        flex-direction: column;
        > span {
          margin-bottom: 15px;
        }
      }
    }
  }
}

//PERSONALIZZAZIONE LANDING SEASON AND SECOND-SEASON
.category-second-season, .category-season{
  .column.main{
    min-height: unset;
  }
}