// Customizzazioni dell'header (gli stili standard sono dentro Magento_Theme styles)
$header-mobile-width: 320px;
$font-size__mobile: 14px;
$header-mobile-padding-top: 32px;
$header-tab-season-height: 49px;
$header-submenu-top: $header-mobile-padding-top + $header-tab-season-height;
$header-mobile-link-padding: 13px;


.page-wrapper{
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  @include transition(all);
}


.page-header{
  background-color: $color-white;
  @include transition(background-color);

  .header-topmessage-container{
    background-color: $color-light-gray;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px 0;
    @include transition(background-color);
    p {
      margin-bottom: 2px;
    }
    span{
      font-size: $font-size__xs;
      text-transform: uppercase;
    }
  }

  .header-toplinks-container{
    display: flex;
    flex-wrap: wrap;

    .header-toplinks-container-left, .header-toplinks-container-right{
      display: flex;
      padding: 10px 0;

      a{
        font-size: $font-size__s;
        text-transform: uppercase;
        margin: 0 20px;
        flex-grow: 0;
        @include transition(color);
      }
    }

    .header-toplinks-container-left{
      justify-content: flex-start;
      width: 65%;

      a{
        &:first-child{
          margin-left: $layout-indent__width;
        }
      }
    }

    .header-toplinks-container-right{
      justify-content: flex-end;
      width: 35%;

      a{
        &:first-child{
          margin-right: $layout-indent__width;
        }
      }

      .block-search{
        background-color: $color-white;
        @include transition(all);
      }

      .switcher{
        margin-right: $layout-indent__width;
        margin-left: 18px;
        font-size: $font-size__s;
        background-color: $color-white;
        @include transition(background-color);

        .switcher-trigger{
          &:after{
            font-size: 30px !important;
            line-height: 16px !important;
            width: 20px !important;
          }
        }

        .options{
          font-size: $font-size__s;
          text-transform: uppercase;

            ul.dropdown{
              right: 0;
              li{
                &:hover{
                  background: unset !important;
                }
              }
            }

        }
      }
    }
  }

  .header-logo-container{
    position: relative;
    .logo{
      svg{
        fill: $color-black;
        @include transition(fill);
      }
    }

    .account, .minicart-wrapper{
      position: absolute;
    }

    .block-search{
      display: none;
    }

    .mobile-search{
      display: none;
    }

    .account{
      right: 105px;
      margin-right: 78px;
      .account-link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .account-text{
          margin-top: 8px;
          text-transform: uppercase;
          font-size: $font-size__s;
        }
      }
    }

    .minicart-wrapper{
      right: $layout-indent__width;
    }
  }

  &.season{
    background-color: $color-white;
    @include transition(background-color);

    .level0{
      &.season {
        background-color: $color-light-gray;
        @include transition(background-color);
        width: 65vw;

        .submenu{
          background-color: $color-light-gray;
        }

        &.second {
          color: $color-light-gray;
          background-color: $color-white;
          @include transition(background-color);
          width: 35vw;

          a{
            color: $color-second-season-inactive;
          }
        }

      }
    }
  }

  &.second-season{
    background-color: $color-black;
    @include transition(background-color);

    .header-topmessage-container{
      background-color: $color-dark-gray;
      color: $color-white;
      @include transition(background-color);

      span, a{
        color: $color-white;
      }
    }

    .header-toplinks-container-left, .header-toplinks-container-right{

      a{
        color: $color-white;
        @include transition(color);
      }
    }


    .header-toplinks-container-right{
      .block-search{
        background-color: $color-black;
        @include transition(all);
      }

      .vesto-search-icon{
        filter: invert(1);
        @include transition(background-color);
      }

      .switcher{
        color: $color-white;
        background-color: $color-black;
        @include transition(background-color);

        .options {
          ul.dropdown{
            background-color: $color-black;
            border: 1px solid $color-white;
            color: $color-white;
          }
        }
      }
    }

    .header-logo-container{
      .logo{
        svg{
          fill: $color-white;
          @include transition(fill);
        }
      }

      .block-search{
        background-color: $color-black;
        @include transition(all);
      }

      .vesto-cart-icon, .vesto-account-icon, .vesto-menu-icon, .vesto-search-icon{
        filter: invert(1);
      }

      .account-text, .showcart .text{
        color: $color-white;
      }
    }

    .logo{
      svg{
        fill: $color-white;
        @include transition(fill);
      }
    }

    .level0{
      &.season {
        background-color: $color-black;
        @include transition(background-color);
        width: 35%;

        a{
          color: $color-season-inactive;
          position: relative;

          .subsub-all{
            @include lib-icon-font($icon-next,
            $_icon-font-size: 34px,
            $_icon-font-line-height: 17px,
            $_icon-font-position: after);

            &:after{
              display: inline-block;
            }
          }
        }

        &.second {
          background-color: $color-dark-gray;
          @include transition(background-color);
          width: 65%;

          a{
            color: $color-white;
          }

          .submenu{
            background-color: $color-dark-gray;
            border-color: $color-white;

            .submenu{

              > div {
                border-color: $color-white;
              }
            }
          }
        }

      }
    }
  }

  .navigation {
    .menu-desktop {
      display: flex;
    }

    .menu-mobile{
      display: none;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {

}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
  .page-header{
    .header-toplinks-container{
      display: none;
    }

    .header-logo-container{

      .block-search{
        display: block;
      }

      .account{
        right: 78px;
        margin-right: 0;
        .account-text{
          display: none;
        }
      }

      .minicart-wrapper{
        right: $layout__width-xs-indent;
        .showcart{
          .text{
            display: none;
          }
        }
      }

      .mobile-search{
        display: block;
        position: absolute;
        right: 140px;

        .open-search{
          display: flex;

          .vesto-search-icon{
            width: 24px;
            height: 24px;
            background-size: contain;
          }
        }
      }
    }

    .navigation {
      .menu-desktop {
        display: none;
      }

      .menu-mobile{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100vh;
        top: 0;
        max-width: $header-mobile-width;
        position: fixed;
        left: calc(-1 * 320px);
        background: $color-white;
        z-index: 4;
        padding-top: 32px;
        box-sizing: border-box;
        @include transition(left);
        overflow: auto;

        .mobile-submenu{
          position: fixed;
          top: $header-submenu-top;
          left: calc(-1 * 320px);
          order: 3;
          display: block;
          width: $header-mobile-width;
          max-height: calc(100vh - 227px);
          box-sizing: border-box;
          @include transition(left);
          z-index: 1;

          &.active{
            left: 0;
            @include transition(left);
          }

          &.inactive{
            left: calc(-100% - 1px) !important;
            @include transition(left);
          }

          &.always-active{
            top: unset;
            bottom: 0px;
            height: 72px;
            border-top: 1px solid $color-black;
            @include transition(left);

            .switcher {
              margin-right: 0;
              padding-left: 25px;
              padding-top: 5px;

              .options {

                .switcher-trigger {
                  span {
                    font-size: $font-size__mobile;
                    font-weight: 400;
                  }

                  &:after {
                    font-size: 44px;
                  }

                  &.active {
                    &:after {
                      font-size: 44px;
                    }
                  }
                }

                .switcher-dropdown {
                  width: $header-mobile-width;
                  font-size: $font-size__xs;
                  left: -25px;
                  border: unset;
                  border-top: 1px solid $color-black;
                  box-shadow: unset !important;
                }

                &.active{
                  .switcher-dropdown {
                    display: flex;

                  }
                }
              }
            }

          }

          > a{
              font-size: $font-size__mobile;
              padding: $header-mobile-link-padding 0 $header-mobile-link-padding 25px;
              text-align: left;
              border-bottom: 1px solid $color-second-season-inactive;
              width: 100%;
              box-sizing: border-box;

              span {
                @include lib-icon-font($icon-next,
                $_icon-font-size: 44px,
                $_icon-font-line-height: 22px,
                $_icon-font-position: after);
                line-height: 22px;

                &:after{
                  position: absolute;
                  right: 0;
                }
              }

              &.back-link-mobile{
                padding-left: 8px;

                span {
                  line-height: 22px;
                  &:after {
                    content: unset;
                  }

                  @include lib-icon-font($icon-prev,
                  $_icon-font-size: 44px,
                  $_icon-font-line-height: 22px,
                  $_icon-font-position: before);
                }
              }
          }

          .mobile-submenu{
            top: $header-submenu-top;
            position: fixed;
            background-color: $color-white;
            width: $header-mobile-width;
            max-height: calc(100vh - 227px);
            z-index: 2;

            &.inactive{
              left: calc(-1 * 320px) !important;
            }

            .mobile-submenu{
              z-index: 3;
              overflow-y: scroll;

              .mobile-submenu{
                z-index: 4;
              }
            }
          }
        }


        .menu-mobile-close{
          @include lib-button-icon(
                          $icon-remove,
                  $_icon-font-size        : 32px,
                  $_icon-font-line-height : 32px,
                  $_icon-font-text-hide   : true
          );
          @include lib-button-reset();
          height: 40px;
          width: 40px;
          text-align: right;
          order: 1;
          position: absolute;
          right: 0;
          top: 0;
        }

        > a {
          width: 50%;
          //height: $header-tab-season-height;
          height: 70px;
          order: 2;
          font-size: $font-size__mobile;
          font-weight: 700;
          padding: 14px;
          text-align: left;
          box-sizing: border-box;
          text-transform: uppercase;
          display: flex;
          align-items: center;

          &.season {
            border-right: 1px solid $color-black;
            text-align: right;
          }

          &.inactive{
            &.season{
              color: $color-season-inactive;
              background-color: $color-black;
            }
            &.second-season{
              color: $color-second-season-inactive;
              background-color: $color-white;
            }
          }

          &.active{
            &.season{
              color: $color-black;
              background-color: $color-light-gray;
            }
            &.second-season{
              color: $color-white;
              background-color: $color-dark-gray;
            }
          }
        }
      }
    }
  }

  .season{
    .menu-mobile{
      background-color: $color-white !important;

      .mobile-submenu{
        background-color: $color-light-gray;

        &.always-active{
          background-color: $color-white;
        }

        .mobile-submenu{
          background-color: $color-light-gray !important;
        }
      }
    }
  }

  .second-season{
    .menu-mobile{
      background-color: $color-black !important;
      color: $color-white;

      .mobile-submenu{
        background-color: $color-dark-gray;
        color: $color-white;

        > a {
          color: $color-white;
        }

        &.always-active{
          background-color: $color-black;
          border-top: 1px solid $color-white !important;

          .switcher-dropdown{
            background: $color-black;
            color: $color-white;

            a{
              color: $color-white !important;
            }

          }
        }

        .mobile-submenu{
          background-color: $color-dark-gray !important;
          color: $color-white !important;

          > a {
            color: $color-white !important;
          }
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .page-header{

    .header.content{
      align-items: flex-end;
      padding-bottom: 15px;
    }

    .header-logo-container{

      .logo{
        width: 166px;
        margin-bottom: 30px;
      }

      .block-search{
        display: block;
      }

      .account{
        right: 78px;
        margin-right: 0;
        .account-text{
          display: none;
        }
      }

      .minicart-wrapper{
        right: $layout__width-xs-indent;
        .showcart{
          .text{
            display: none;
          }
        }
      }

      .mobile-search{
        display: block;
        position: absolute;
        right: 140px;

        .open-search{
          display: flex;

          .vesto-search-icon{
            width: 24px;
            height: 24px;
            background-size: contain;
          }
        }
      }
    }
  }
}