.page-footer {
    //margin-top: 50px;
    .container {
        .block-title {
            text-align: center;
        }
    }
}
.value-proposition {
    display: grid;
    grid-template-columns: repeat(3, auto);
    width: 100%;
    max-width: $layout__max-width;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 2%;
    .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        > img {
            margin: auto;
        }
        span {
            font-size: $font-size__base;
            text-transform: uppercase;
            font-weight: $font-weight__bold;
            margin-top: 14px;
            width: 100%;
        }
    }
}
.footer.content {
    background-color: $color-black;
    color: $color-white;
    font-size: $font-size__xs;
    padding: 0;
    max-width: 100%;
    .container {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        margin: 35px auto;
        max-width: $layout__max-width;
        .colm {
            &__title {
                margin-bottom: 10px;
            }
            &__list {
                line-height: 3;
                li > a {
                    color: $color-white;
                    &:hover, &:focus, &:visited {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }
            }
        }
        .registration-form {
            margin-top: 0;
            .button-container {
                margin: 0;
                max-width: 100%;
                justify-content: flex-end;

            }
            a.link {
                color: $color-white;
                padding-right: 15%;
            }
            .button.secondary {
                background: $color-white;
                text-align: center;
                &:hover {
                    color: $color-black;
                }
            }
        }
    }
    .copyright {
        text-align: center;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        margin: 0 42px;
        align-items: center;
        //flex-direction: column;
        //span {
        //    margin-bottom: 10px;
        //}
        a {
            color: $color-white;
            //margin-bottom: 10px;
            margin-left: 15px;
        }
        > img {
            //margin: auto;
            margin-left: 15px;
            max-width: 203px;
            &.payment {
                height: 25px;
            }
        }
        #credits-popup {
            display: none;
            position: fixed;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 10000;
            width: 100%;
            height: 100%;
            .popup-content {
                width: 30%;
                max-width: 580px;
                max-height: 500px;
                overflow-y: scroll;
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 20px 0;
                transform: translate(-50%, -50%);
                background: $color-white;
                border-radius: 20px;
                color: $color-black;
                border: 2px solid $color-black;
                @media (max-width: $screen__m) {
                    width: 50%;
                }
                a {
                    color: $color-black;
                }
                ul {
                    display: flex;
                    flex-direction: row;
                    list-style: none;
                    justify-content: space-around;
                    align-items: center;
                    padding-left: 0;
                    margin-bottom: 0;
                    li {
                        font-size: 12px;
                        padding: 20px;
                        label {
                            margin-top: 10px;
                            display: block;
                            font-size: $font-size__s;
                        }
                    }
                }
            }
            #closecredits {
                position: absolute;
                top: 0;
                right: 0;
                padding: 20px;
                &:before {
                    font-size: 32px;
                    line-height: 20px;
                    color: inherit;
                    content: "";
                    font-family: "icons-blank-theme";
                    margin: 0;
                    vertical-align: top;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
                span {
                    display: none;
                }
            }
            &.open {
                display: block;
            }
        }
    }
}
@media (max-width: $layout__max-width) {
    .footer.content {
        .container {
            margin: 35px $layout-indent__width;
        }
    }
}
@media (max-width: $screen__l) {
    .footer.content {
        .container .registration-form {
            height: 100%;
            .button-container {
                flex-direction: column;
                height: 100%;
                justify-content: start;
                a.link {
                    padding-right: 0;
                    margin-bottom: 27px;
                }
            }
        }
    }
}
@media (max-width: $screen__m) {
    .page-footer {
        .container {
            margin-left: $layout__width-xs-indent;
            margin-right: $layout__width-xs-indent;
        }
    }
    //.footer.content {
    //    .container {
    //        grid-template-columns: repeat(2, 50%);
    //        row-gap: 40px;
    //    }
    //}
    .footer.content {
        margin-top: 0;
        .container {
            //grid-template-columns: repeat(1, 100%);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 0;
            margin-left: $layout__width-xs-indent;
            margin-right: $layout__width-xs-indent;
            .colm {
                flex-basis: 50%;
                margin-bottom: 40px;
                &:last-child {
                    flex-basis: 100%;
                }
                &.social {
                    flex-basis: 100%;
                    .colm__title {
                        display: none;
                    }
                    .colm__list {
                        display: flex;
                        justify-content: space-evenly;
                    }
                }
            }
            //.registration-form {
            //    a.link {
            //        padding-right: 0;
            //        margin-bottom: 27px;
            //    }
            //}
        }
        .copyright {
            margin-left: $layout__width-xs-indent;
            margin-right: $layout__width-xs-indent;
            flex-direction: column;
            > a {
                margin-left: 0;
            }
            img {
                margin: auto;
            }
        }
    }
    .value-proposition {
        column-gap: 5%;
        .item {
            justify-content: space-between;
        }
    }
}
@media (max-width: $screen__s) {
    .page-footer {
        .container {
            margin-bottom: 34px;
        }
    }
    //.footer.content {
    //    margin-top: 0;
    //    .container {
    //        //grid-template-columns: repeat(1, 100%);
    //        display: flex;
    //        flex-direction: row;
    //        flex-wrap: wrap;
    //        margin-bottom: 0;
    //        margin-left: $layout__width-xs-indent;
    //        margin-right: $layout__width-xs-indent;
    //        .colm {
    //            flex-basis: 50%;
    //            margin-bottom: 40px;
    //            &:last-child {
    //                flex-basis: 100%;
    //            }
    //        }
    //        .registration-form {
    //            a.link {
    //                padding-right: 0;
    //            }
    //        }
    //    }
    //}
    .value-proposition {
        grid-template-columns: auto;
        row-gap: 70px;
        margin-bottom: 0;
    }
}
