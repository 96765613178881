@mixin fullpage-width {
  box-sizing: border-box;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media (min-width: $layout__max-width + 1) {
    max-width: $layout__max-width;
    margin: auto;
    left: unset;
    right: unset;
  }
}

@mixin style-select {
    color: $color-black;
    line-height: 1.3;
    padding: 0 1.4em 0 .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../images/icons/caret_down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    &::-ms-expand {
      display: none;
    }

    &:hover {
      border-color: $color-black;
    }

    &:focus {
      border-color: $color-black;
      color: $color-black;
      outline: none;
    }

    option {
      font-weight:normal;
    }
}