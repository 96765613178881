//
//  Shopping cart
//  ---------------------------------------------

.checkout-cart-index {
    .page-title-wrapper {
        h1 {
            font-size: $font-size__s;
            text-transform: uppercase;
            margin-top: 38px;
            span {
                padding: 20px;
                border: 1px solid $color-black;
                display: none;
            }
        }
    }
}
//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    //@include lib-css(background, $sidebar__background-color);
    margin-bottom: $indent__m;
    padding: 1px 15px $indent__m;
    border: 1px solid $color-black;

    > .title {
        display: block;
        @include lib-heading(h3);
        text-transform: uppercase;
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
        &.shipping {
            > .content {
                display: block !important;
                select {
                    @include style-select;
                }
                .radio.selected:before {
                    background: $color-black;
                }
            }
        }
        &.discount {
            .title {
                padding-top: 20px;
                #block-discount-heading {
                    text-transform: uppercase;
                    font-size: $font-size__base;
                    font-weight: $font-weight__bold;
                }
                &:after {
                    top: 12px;
                }
            }
            .content {
                .primary {
                    .action.primary {
                        font-size: $font-size__s;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        color: $color-black;
                        border: 1px solid $color-black;
                        border-radius: 0;
                        background: transparent;
                        padding: 20px;
                        max-width: 245px;
                        line-height: 2.4rem !important;
                        &:hover, &:visited, &:active {
                            background: $color-black;
                            color: $color-white;
                        }
                    }
                }
            }
            &.active {
                .title {
                    @include lib-icon-font(
                            $_icon-font-content     : $icon-up,
                            $_icon-font-size        : 30px,
                            $_icon-font-line-height : inherit,
                            $_icon-font-text-hide   : false,
                            $_icon-font-position    : after,
                            $_icon-font-display     : block
                    );
                }
            }
        }
    }
    .actions-toolbar {
        > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    thead {
        display: none;
    }
    .items {
        thead + .item {
            //border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            position: relative;
            &:after {
                content: '';
                display: block;
                height: 15px;
            }
            .item-info {
                border: $border-width__base solid $color-black;
            }
        }
    }


    .col {
        padding-top: 27px;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                width: 55px;
                margin-top: -5px;
                border: none;
                border-bottom: 1px solid $color-black;
            }
            .control.qty {
                label {
                    display: flex;
                }
            }
            .label {
                @extend .abs-visually-hidden;
            }
        }
        .actions-toolbar > .action {
            text-transform: uppercase;
            font-weight: $font-weight__regular;
            font-size: $font-size__s;
            padding-top: 0;
            -webkit-appearance: none;
            border: none;
            background: transparent;
            &:hover, &:active, &:focus {
                text-decoration: underline;
            }
            &.action-edit {
                display: none;
            }
            &.action-delete {
                @include lib-button-icon(
                                $icon-remove,
                        $_icon-font-size        : 32px,
                        $_icon-font-line-height : 32px,
                        $_icon-font-text-hide   : true
                );
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            //width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: 0;
            > a {
                &:hover, &:visited, &:focus {
                    text-decoration: underline;
                }
            }
        }
        &-item-sku {
            padding: 10px 0;
            font-size: $font-size__s;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
        width: 70%;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
            background: $color-black;
            font-size: $font-size__s;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $color-white;
            padding: 20px;
            border: 1px solid $color-black;
            max-width: 245px;
            line-height: 2.4rem !important;
            border-radius: 0;
            &:hover, &:visited, &:active {
                background: $color-white;
                border: 1px solid $color-black;
                color: $color-black;
            }
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

.cart.main.actions {
    display: none;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                .actions-toolbar {
                    > .action {
                        vertical-align: top;
                        &.action-delete:before {
                            line-height: 20px;
                        }
                    }
                }
                .product-item-photo {
                    position: static;
                    display: table-cell;
                    vertical-align: top;
                    max-width: 100%;
                }
                .product-item-details {
                    display: table-cell;
                    vertical-align: top;
                }
                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                    padding: 27px 8px 9.5px;
                }
            }
        }
    }

    .cart-container {
        display: flex;
        flex-direction: column;
        .cart-summary {
            order: 2;
        }
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
            width: 100%;
        }
    }
}
@include max-screen($screen__s) {
    .cart-container .form-cart .cart.table-wrapper {
        tbody.cart.item {
            .item-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                .col {
                    &.item {
                        width: calc(100% - 55px);
                    }
                    &.qty {
                        width: 50%;
                        padding: 8px 0;
                        .control.qty label {
                            justify-content: center;
                            .input-text {
                                width: 100px;
                            }
                        }
                    }
                    &.subtotal {
                        width: 50%;
                        padding: 8px 0;
                        .price-excluding-tax, .price-including-tax {
                            text-align: center;
                        }
                    }
                    &.actions {
                        position: absolute;
                        top: 0;
                        right: 0;
                        .actions-toolbar {
                            > .action {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 29%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                }
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}
