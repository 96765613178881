.init-rma-container{
  .mst-rma-box__title{
    margin-bottom: 20px;
  }
  .primary{
    margin-top: 40px;
  }
}

.ui-rma-order-container{
  .mst-rma-create__order-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .increment-id{
      font-size: 27px;
    }

    .remove{
      text-decoration: underline;
    }
  }
  .rma-new-step2{
    .block{
      border: 1px solid $color-black;
      margin-bottom: 20px;
      padding: 15px;

      .box-rma-information{
        .box-content{
          table{
            tbody{
              tr{
                display: flex;

                th{
                  padding-left: 0;
                }
              }
            }
          }
        }
      }

      .ui-rma-items{
        .rma-one-item{
          .item-description{
            display: flex;
            margin-bottom: 20px;

            input, .image{
              margin-right: 20px;
            }

          }
          .item-options{
            margin-bottom: 20px;

            .field{
              margin-bottom: 10px;
              display: flex;
              flex-wrap: wrap;
              .label{
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.mst-rma-view{
  .opc-progress-bar{
    width: 100%;
    margin-bottom: 40px;

    .opc-progress-bar-item{
      width: 25%;
    }
  }
}

.mst-rma-list__new-request{
  margin-bottom: 40px;
}

.mst-rma-list{
  .mst-rma-list__items{
    ul{
      display: none;
    }
  }
}

.mst-rma__status{
  margin-bottom: 40px;
}