//
//  Variables
//  _____________________________________________

$account-nav-background              : $sidebar__background-color !default;
$account-nav-color                   : false !default;

$account-nav-current-border          : 3px solid transparent !default;
$account-nav-current-border-color    : $color-black !default;
$account-nav-current-color           : false !default;
$account-nav-current-font-weight     : $font-weight__semibold !default;

$account-nav-delimiter__border-color : $color-gray82 !default;

$account-nav-item-hover              : $color-gray91 !default;

$_password-default                   : $color-gray-light01 !default;
$_password-weak                      : #ffafae !default;
$_password-medium                    : #ffd6b3 !default;
$_password-strong                    : #c5eeac !default;
$_password-very-strong               : #81b562 !default;

.privacy-check {
    display: flex;
    flex-direction: row;
    label {
        padding-top: 0 !important;
        padding-left: 5px !important;
    }
    > input {
        margin-top: 4px;
    }
}
body[class^="customer-account-"], body.account {
    .page-title-wrapper {
        display: none;
    }
    .columns {
        margin: 6% 0;
        .sidebar-main {
            order: 0;
        }
    }
    .legend span {
        text-transform: uppercase;
        font-size: $font-size__base;
    }
    .primary a, .primary button {
        background-color: $color-black;
        font-size: $font-size__s;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $color-white;
        padding: 20px;
        border: 1px solid $color-black;
        max-width: 245px;
        min-width: 245px;
        border-radius: 0;
        font-weight: $font-weight__regular;
        &:hover, &:visited, &:active {
            background: $color-white;
            border: 1px solid $color-black;
            color: $color-black;
        }
        span {
            font-size: $font-size__s;
        }
    }
    .actions-toolbar {
        float: right;
        &.bottom {
            float: left;
        }
    }
    .fieldset > .field {
        max-width: 450px;
        .control {
            float: unset;
            max-width: 450px;
            width: 100%;
            select {
                @include style-select;
            }
        }
        > .label {
            font-weight: $font-weight__regular;
            text-align: left;
            width: fit-content;
            margin-bottom: 10px;
        }
        &.password {
            .control {
                display: block;
            }
        }
    }
    .form {
        .actions-toolbar {
            margin-left: 0;
            .action.back {
                text-transform: uppercase;
                font-size: $font-size__s;
            }
            .primary {
                button {
                    background-color: $color-black;
                    font-size: $font-size__s;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: $color-white;
                    padding: 20px;
                    border: 1px solid $color-black;
                    max-width: 245px;
                    border-radius: 0;
                    font-weight: $font-weight__regular;
                    &:hover, &:visited, &:active {
                        background-color: $color-white;
                        border: 1px solid $color-black;
                        color: $color-black;
                    }
                }
            }
            .secondary {
                a {
                    font-size: $font-size__s;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: $font-weight__regular;
                }
            }
        }
        &.password.forget {
            width: 100%;
            button.captcha-reload {
                font-size: $font-size__s;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $color-black;
                border: 1px solid $color-black;
                padding: 20px;
                max-width: 245px;
                background: transparent;
                border-radius: 0;
                font-weight: $font-weight__regular;
                &:hover, &:visited, &:active {
                    background-color: $color-black;
                    color: $color-white;
                }
            }
            .actions-toolbar {
                float: unset;
                position: unset !important;
            }
        }
    }
    .pager {
        .limiter {
            .limiter-options {
                border: none;
                border-bottom: 1px solid $color-black;
                border-radius: 0;
                @include style-select;
            }
        }
    }
    .actions-toolbar {
        .back {
            text-transform: uppercase;
            font-size: $font-size__s;
        }
    }
}
.newsletter-manage-index {
    .actions-toolbar {
        float: unset !important;
    }
}
.account.sales-order-view {
    .actions-toolbar {
        float: unset;
    }
    .order-links {
        border: none;
        .nav.item {
            > strong {
                font-weight: $font-weight__regular;
                text-transform: uppercase;
            }
        }
    }
    .order-title {
        display: none;
    }
}
// pagina di login
.login-container {
    margin: 6%;
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
            text-align: left;
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);
        position: relative;
        &:after {
            margin-top: 35px;
            position: absolute;
            bottom: 100px;
        }
        .actions-toolbar {
            margin-top: 30px;
        }
    }
    .actions-toolbar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        float: unset;
        &:before {
            content: none;
        }
        .primary {
            float: unset;
        }
        .secondary {
            float: unset;
        }
    }
}
// pagina di registrazione
.customer-account-create {
    .info-text {
        float: left;
        width: 50%;
        .title {
            font-weight: $font-weight__bold;
            font-size: $font-size__l;
            margin-bottom: 23.75px;
        }
        ul {
            list-style: none;
            padding-left: 0;
            padding-right: 20px;
            li {
                margin-bottom: 20px;
            }
        }
    }
    form.create {
        float: left;
        border: 1px solid $color-black;
        width: fit-content !important;
        min-width: 28% !important;
        max-width: 35%;
        padding: 30px;
        .fieldset {
            > .field {
                &.choice.newsletter:before {
                    padding: 0;
                    width: 0;
                }
                &.privacy-check {
                    > .label {
                        float: unset;
                    }
                }
            }
            //&.address {
            //    .field {
            //        width: 49%;
            //        float: left;
            //        &:nth-child(even) {
            //            margin-right: 10px;
            //        }
            //    }
            //}
        }
        .actions-toolbar {
            text-align: center;
            margin: 0;
            display: block;
            .primary {
                float: unset;
            }
        }
    }
    .actions-toolbar {
        float: left;
        width: 100%;
        margin: 40px 0;
        display: none;
        a {
            font-size: $font-size__s;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: $font-weight__regular;
        }
    }
}
.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset {
        > .field.choice:before {
            width: 0;
            padding: 0;
        }
        &.password {
            display: none;
        }
    }

}

.box-information {
  .box-actions {
    a.edit {
        margin-right: 15px;
    }
  }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;
            .limiter-options {
                width: auto;
            }
        }

        .limiter {
            >.label {
                @extend .abs-visually-hidden;
            }
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
            .block-title {
                > strong {
                    font-weight: $font-weight__regular;
                    font-size: $font-size__base;
                }
                a.action {
                    font-weight: $font-weight__regular;
                    font-size: $font-size__s;
                }
            }
            .box-title > span {
                text-transform: uppercase;
            }

        }
    }
    .block-title {
        text-align: left;
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;
            text-transform: uppercase;
            font-size: $font-size__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        //@include lib-css(background, $account-nav-background);
        padding: 15px 0;
        border: 1px solid $color-black;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
            font-weight: $font-weight__regular;
            font-size: $font-size__s;
            text-transform: uppercase;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &.current {
            a,
            strong {
                //@include lib-css(border-color, $account-nav-current-border-color);
                //@include lib-css(color, $account-nav-current-color);
                //@include lib-css(font-weight, $account-nav-current-font-weight);
                text-decoration: underline;
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;
    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);
            .column.main & {
                font-size: $h3__font-size;
            }
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
        a.action {
            font-size: $font-size__s;
            text-transform: uppercase;
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
            z-index: 2;
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    body[class^="customer-account-"], body.account {
        .sidebar {
            display: none;
        }
        .fieldset > .field {
            max-width: 100%;
            .control {
                max-width: 100%;
            }
        }
        .actions-toolbar {
            position: absolute;
            top: 0;
            text-transform: uppercase;
            font-size: $font-size__s;
            .action.back {
                @include lib-icon-font(
                        $_icon-font-content     : $icon-prev,
                        $_icon-font-size        : 28px,
                        $_icon-font-line-height : 16px,
                        $_icon-font-text-hide   : false,
                        $_icon-font-position    : before,
                        $_icon-font-display     : block
                );
            }
            &.bottom {
                position: static;
            }
        }
    }
    .newsletter-manage-index {
        form {
            .actions-toolbar {
                position: static;
            }
        }
    }
    body.customer-account-index {
        .sidebar {
            display: block;
        }
        //.column {
        //    .block:not(.block-dashboard-orders) {
        //        width: 50%;
        //        float: left;
        //    }
        //}
    }
    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
        .actions-toolbar {
            position: static !important;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
    .customer-account-create {
        .info-text {
            width: 100%;
            float: unset;
            ul {
                column-count: 2;
                margin-bottom: 20px;
                padding-right: 0;
            }
        }
        form.create {
            float: unset;
            max-width: 100%;
            width: unset !important;
            padding: 15px;
            .password-strength-meter {
                height: unset;
            }
        }
        .actions-toolbar {
            float: unset !important;
            position: static !important;
        }
    }
    .account {
        .page.messages {
            margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }

            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    .form.password.forget>div{
        max-width: 450px;
    }

    //
    //  My account
    //  ---------------------------------------------

    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 22.3%;
        }

        .column.main {
            width: 77.7%;
        }
    }

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}
