$filter-icon-font-size: 24px;
$filter-icon-line-height: 13px;

.block.filter {
    margin-bottom: $indent__xl;

    .filter-title {
        padding: 20px 10px 20px $layout-indent__width;
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
        box-sizing: border-box;
        text-transform: unset;
        margin-top: 73px;
        background-color: $color-light-gray;
        text-align: left;

        strong {
            font-size: $font-size__base;
        }
    }
}

.close-mobile-filter{
    display: none;
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    padding-left: $layout-indent__width;
    padding-top: 30px;
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        padding-left: $layout-indent__width;
        margin-top: 15px;
        margin-bottom: 30px;
        text-decoration: underline;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        font-size: $font-size__base;
        padding: 20px 0 20px $layout-indent__width;
        border-bottom: 1px solid $color-second-season-inactive;
        display: block !important;
        position: relative;

        @include lib-icon-font(
                $_icon-font-content     : $icon-expand,
                $_icon-font-size        : $filter-icon-font-size,
                $_icon-font-line-height : $filter-icon-line-height,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after
        );

        &:after{
            position: absolute;
            right: 0;
            top: 24px;
        }
    }

    .filter-options-item{
        &.active{
            .filter-options-title {
                @include lib-icon-font(
                        $_icon-font-content     : $icon-collapse,
                        $_icon-font-size        : $filter-icon-font-size,
                        $_icon-font-line-height : $filter-icon-line-height,
                        $_icon-font-text-hide   : false,
                        $_icon-font-position    : after
                );
            }
        }
    }

    .filter-options-content {
        padding-top: 10px;
        padding-left: $layout-indent__width;

        .item {
            margin-bottom: 8px;
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @include lib-icon-font(
                    $_icon-font-content     : $icon-remove,
                    $_icon-font-size        : $filter-icon-font-size,
                    $_icon-font-line-height : $filter-icon-line-height,
                    $_icon-font-text-hide   : false,
                    $_icon-font-position    : after
            );
            right: 0;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

.open-filter {
    overflow: hidden;

    .page-wrapper {
        right: 320px;
        left: -320px;
        @include transition(all);
    }
}

//
//  Mobile
//  _____________________________________________

$sidebar-width-mobile: 320px;


@include max-screen($screen__l) {

    .close-mobile-filter{
        display: block;

        @include lib-icon-font(
                $_icon-font-content     : $icon-remove,
                $_icon-font-size        : 32px,
                $_icon-font-line-height : 32px,
                $_icon-font-text-hide   : true,
                $_icon-font-position    : after
        );

        &:after{
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
                background-color: $color-white;
                position: fixed;
                top: 0;
                right: calc(-1 * 320px);
                max-width: $sidebar-width-mobile;
                height: 100vh;
                overflow-y: auto;
                @include transition(right);

                &:before {
                    content: '';
                    right: 0;
                    @include transition('right');
                }
            }
        }
    }

    .open-filter{
        .page-with-filter {
            .page-header{
                z-index: 0;
            }

            .columns {
                z-index: 4;
                .sidebar-main {
                    right: 0;
                    padding-right: 0;
                    @include transition(right);

                    &:before {
                        background: rgba(0, 0, 0, 0.5);
                        content: '';
                        display: block;
                        height: 100%;
                        position: fixed;
                        top: 0;
                        right: 320px;
                        width: 100%;
                        z-index: 100;
                        @include transition(right);
                    }
                }

                .block.filter {
                    margin-bottom: $indent__xl;

                    .filter-title {
                        margin-top: 32px;
                    }

                    .filter-options{
                        .filter-options-title{
                            padding: 16px 20px;

                            &:after{
                                right: 12px;
                            }
                        }

                        .filter-options-content{
                            padding-left: 20px;

                            .item {
                                margin-bottom: 12px;
                            }
                        }
                    }

                    .filter-current {
                        padding-left: 20px;
                        padding-right: 10px;
                    }

                    .filter-current + .filter-actions{
                        padding-left: 20px;
                    }
                }

                .filter-register-login{
                    padding-left: unset;

                    .registration-form{
                        padding: 32px 20px;
                        display: block !important;
                    }
                }
                .filter-value-proposition {
                    padding: 0 20px;
                }
            }
        }
    }
}
