.catalog-product-view {
    .columns {
        .column.main {
            order: 0;
            padding-bottom: 10px;
        }
    }
}
.page-layout-1column .product-info-main {
    width: 28%;
}
.product-info-main {
    max-width: 490px;
    padding-right: 7.5%;
    margin-top: 30px;
    h1.page-title {
        font-size: $font-size__l;
        font-weight: $font-weight__regular;
        line-height: 1.36;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .attribute.manufacturer {
        text-align: center;
        font-weight: $font-weight__bold;
        margin-bottom: 20px;
    }
    .product-info-stock-sku {
        margin-bottom: 26px;
        .stock.available, .stock.unavailable {
            display: none;
        }
        .attribute.sku {
            strong {
                display: none;
            }
            font-size: $font-size__l;
            font-weight: $font-weight__bold;
            color: $color-black !important;
        }
    }
    .product-info-price {
        position: relative;
        //border-top: 1px solid #979797;
    }
    .bss-price-unit {
        font-size: $font-size__l;
        .bss-price-unit-value {
            font-weight: $font-weight__bold;
        }
    }
    .premium-price {
        font-size: $font-size__base;
        padding: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        span, .price {
            display: inline-block;
        }
        .price {
            margin-left: 5px;
        }
    }
    .retail_price {
        font-size: $font-size__base;
        text-align: right;
        //position: absolute;
        //right: 0;
        //top: 0;
        padding: 18px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        span, .price {
            display: inline-block;
        }
        .price-details-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .price {
            margin-left: 5px;
        }
        .tooltip {
            font-size: 11px;
            border-radius: 100%;
            border: 1px solid black;
            padding: 2px 5.5px;
            margin-left: 15px;
            position: relative;
            .tooltiptext {
                visibility: hidden;
                width: 200px;
                background-color: $color-white;
                color: $color-black;
                text-align: center;
                border-radius: 6px;
                padding: 5px 5px;
                position: absolute;
                z-index: 1;
                top: 125%;
                //left: 50%;
                left: 20%;
                margin-left: -100px;
                opacity: 0;
                transition: opacity 0.3s;
                border: 1px solid $color-black;
                font-size: $font-size__s;
                &:before {
                    border-bottom-color: $color-black;
                }
                &:after {
                    border-bottom-color: $color-white;
                }
            }
        }
        .tooltip:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
    }
    .price-box {
        display: flex;
        //flex-direction: column;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid #979797;
        border-top: 1px solid #979797;
        .normal-price, .old-price, .bss-price-range, .unit-price, .retail_price {
            padding: 15px 0;
            width: 50%;
        }
        .unit-price.w-discount {
            width: 100%;
            border-top: 1px solid #979797;
        }
        .price-label {
            font-size: $font-size__base !important;
            display: inline !important;
        }
        .normal-price {
            order: 2;
            .price-label {
                font-size: $font-size__l !important;
            }
            .price-wrapper {
                .price {
                    font-size: $font-size__l !important;
                    font-weight: $font-weight__bold !important;
                }
            }
        }
        .old-price {
            //order: 1;
            display: block !important;
            text-decoration: unset;
            .price-label {
                text-decoration: none;
                &:after {
                    content: none;
                }
            }
            .price-wrapper {
                .price {
                    text-decoration: line-through;
                }
            }
        }
        .price-wrapper {
            .price {
                font-size: $font-size__base !important;
                font-weight: $font-weight__regular !important;
                padding-left: 5px;
            }
        }
    }
    .box-tocart {
        width: 100%;
        .actions {
            width: 100%;
            display: block !important;
            .action.tocart {
                font-size: $font-size__base;
                max-width: 100%;
                padding: 10px;
                width: 100%;
                border-radius: 0;
            }
        }
        .fieldset{
            .field.qty {
                padding-bottom:15px;
            }
            .control{
                display: inline;
                .btn-qty{
                    font-size: 1.3em;
                }
                .minus{
                    padding-right: 5px;
                    padding-left: 10px;
                }
                .plus{
                    padding-right: 10px;
                    padding-left: 5px;
                }
                #qty-error{
                    position: absolute;
                }
            }
        }
    }
    .product-add-form {
        position: relative;

        .size-guide_link {
            float: right;
            right: 0;
            top: 0;
            margin-top: 30px;
            margin-bottom: 30px;
            z-index: 2000;
            font-size: $font-size__mobile;
        }
        .swatch-option {
            min-width: 24px;
            //max-width: 24px;
            min-height: 24px;
            max-height: 24px;
            &.selected {
               outline: 1px solid $color-black;
                border:none;
            }
        }
        .info-cart {
            margin: 10px;
            margin-bottom: 25px;
            border: 1px solid $color-black;
            padding: 15px;
            text-align: center;
            display: block;
            text-transform: uppercase;
            font-size: $font-size__s;
            background: $color-black;
            color: $color-white;
            &:hover, &:active {
                background: $color-white;
                color: $color-black;
            }
        }
        .availability{
            &.stock {
                position: absolute;
                left: 150px;
                top: 95px;
            }
            .qty {
                color:green;
            }
        }

    }
    .product-options-wrapper {
        .field.configurable {
            display: none;
        }
        .bss-ptd-table {
            //margin-top: 50px;
            //position: relative;
            table {
                thead tr th {
                    font-weight: $font-weight__bold;
                    padding: 8px 0;
                    font-size: $font-size__base;
                    padding-bottom: 34px;
                    text-align: center;
                    &:first-child {
                        text-align: left;
                        width: 15%;
                    }
                    &:last-child {
                        width: 30%;
                    }
                }
                tbody tr td {
                    padding-top: 0;
                    text-align: center;
                    padding-bottom: 0;
                    vertical-align: bottom;
                    line-height: 28px;
                    //height: 17px;
                    min-height: 17px;
                    vertical-align: top;
                    &.bss-qty-col {
                        //display: flex;
                        flex-direction: row;
                        justify-content: center;
                        .btn-qty {
                            width: 10px;
                            font-size: $font-size__base;
                            color: $color-black;
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                        input {
                            max-width: 64px;
                            border: none;
                            border-bottom: 1px solid $color-black;
                            text-align: center;
                            height: 18px;
                            padding-bottom: 4px;
                            transition: all 300ms ease-in-out;
                        }
                    }
                }
                tr > th {
                    width: 20%;
                }
                tfoot {
                    display: none;
                }
            }
            .bss-table-row {
                .swatch-option {
                    border: none;
                    margin-bottom: 0;
                    &.selected {
                        outline: none;
                        border: none;
                    }
                }
                td[class^="bss-unitprice-"], td[class*="bss-unitprice-"]  {
                    display: none;
                    //display: flex;
                    //flex-direction: row;
                    //justify-content: center;
                    //align-items: flex-end;
                    //flex-wrap: wrap;
                    .bss-old-price {
                        font-size: $font-size__s;
                        text-decoration: line-through;
                        padding-left: 5px;
                    }
                }
            }
        }
        .bss-note-detailed.mage-error {
            position: absolute;
            bottom: 0;
        }
    }
    .contact_link {
        margin-top: 50px;
        a {
            color: $color-black;
            text-decoration: underline;
            &:hover, &:visited {
                text-decoration: none;
            }
        }
    }
}
.popup-sizeguide {
    display: none;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    #closepopup {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        &:before {
            font-size: 32px;
            line-height: 20px;
            color: inherit;
            content: "";
            font-family: "icons-blank-theme";
            margin: 0;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
        span {
            display: none;
        }
    }
    .popup-content {
        width: 70%;
        max-height: 500px;
        overflow-y: scroll;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 20px 0;
        transform: translate(-50%, -50%);
        background: $color-white;
        border-radius: 20px;
        color: $color-black;
        border: 2px solid $color-black;
        .title {
            text-transform: uppercase;
            margin-bottom: 20px;
            padding: 0 20px;
            max-width: 75%;
        }
        .product.data.items {
            overflow: hidden;
            > .item.title {
                width: 33.33%;
                margin: 0;
                padding: 0;
                .switch {
                    background: transparent;
                    border-color: $color-black;
                    text-transform: initial;
                    text-align: center;
                    border-right: none;
                }
                &:first-child .switch {
                    border-left: none;
                }
                &.active {
                    background-color: #f6f6f6;
                }
            }
            > .item.content {
                border-color: $color-black;
                .title {
                    padding: 0;
                    margin-top: 20px;
                }
                .table-container {
                    overflow-x: scroll;
                }
                table {
                    overflow-x: scroll;
                    th {
                        font-weight: $font-weight__regular;
                        background: #f6f6f6;
                        border: 2px solid $color-white;
                        text-transform: uppercase;
                        min-width: 150px;
                    }
                    td {
                        background: #f6f6f6;
                        border: 2px solid $color-white;
                        text-align: center;
                    }
                }
            }
        }
    }
    &.open {
        display: block;
    }
}
.product.media {
    margin-bottom: 6% !important;
    width: 59%;
    margin-top: 30px;
    .fotorama__wrap {
        display: flex;
        flex-direction: row-reverse;
        .fotorama__stage {
            position: static;
            max-width: 74% !important;
            .fotorama__stage__shaft {
                margin: auto !important;
            }
        }
        .fotorama__nav-wrap {
            position: static;
            max-width: 26%;
            .fotorama__nav {
                max-width: 100%;
                .fotorama__thumb__arr {
                    max-width: 100%;
                    display: none;
                }
                .fotorama__thumb-border {
                    max-width: 100%;
                    border: none;
                    background-image: none;
                    max-height: 100%;
                    //margin-top: 20px;
                }
                .fotorama__nav__frame {
                    margin-bottom: 20px;
                    max-width: 100%;
                    max-height: 100%;
                    .fotorama__thumb {
                        background-color: transparent;
                        > .fotorama__img {
                            left: 50%;
                            max-height: 100%;
                            transform: translate(-50%, -50%);
                            width: auto;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.product.info.detailed {
    background-color: $color-white;
    @include fullpage-width;
    .product.data.items {
        .data.item.title {
            .switch {
                border: none;
                font-size: $font-size__l;
                font-weight: $font-weight__regular;
                color: #bdbdbd;
                padding-bottom: 23px;
                padding-right: 44px;
                padding-left: 44px;
                padding-top: 12px;
                position: relative;
                background: transparent;
            }
            &.active {
                .switch {
                    color: $color-black;
                    text-decoration: underline;
                }
            }
        }
        #tab-label-details_tab {
            padding-left: 92px;
            .switch {
                padding-left: 0;
                &:after {
                    content: '';
                    width: 1px;
                    height: 40px;
                    background: $color-black;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
        .data.item.content {
            margin-top: 54px !important;
            border-top: none;
            background-color: #f6f6f6;
            padding: 23px 19px 10px 92px;
            ul {
                padding: 0;
                list-style: none;
                margin-bottom: 0;
                li {
                    padding-bottom: 10px;
                    .title {
                        font-weight: $font-weight__bold;
                    }
                }
            }
            .store-information {
                display: none;
            }
            .form.contact {
                .legend {
                    display: none;
                }
                .container-fields {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-end;
                }
                .fieldset {
                    width: 79%;
                    display: inline-block;
                    margin-bottom: 0;
                    .field {
                        flex-basis: 30%;
                        margin-bottom: 20px;
                        &.note {
                            display: none;
                        }
                        &.telephone {
                            display: none;
                        }
                        .label {
                            width: fit-content;
                        }
                        &.comment {
                            flex-basis: 75%;
                            &::placeholder {
                                color: #000;
                            }
                            .label {
                                display: none;
                            }
                        }
                        &.privacy-check {
                            flex-basis: 15%;
                            padding-bottom: 4px;
                            padding-left: 20px;
                        }
                        .control {
                            margin-top: 10px;
                            input {
                                max-width: 350px;
                            }
                        }
                    }
                }
                .actions-toolbar {
                    width: 20%;
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: 0;
                    padding-bottom: 26px;
                    button {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
// prodotti correlati upsell
.block {
    &.related, &.upsell, &.crosssell {
        max-width: $layout__max-width;
        width: 100%;
        margin-bottom: 6% !important;
        .block-content {
            .products {
                .product-items {
                    display: block;
                    width: 100%;
                    max-width: $layout__max-width;
                    &.owl-carousel{
                        .owl-item {
                            img{
                                height: auto;
                            }
                        }

                        .owl-nav{
                            top: 30%;
                        }

                        .owl-prev {
                            background-size: 26px 26px !important;
                            background-repeat: no-repeat !important;
                            background-position: center;
                            width: 100px;
                            height: 100px;
                            left: 20%;
                            background-color: $color-white;
                            border: 1px solid $color-black;
                            border-radius: 0;
                        }
                        .owl-next {
                            background-size: 26px 26px !important;
                            background-repeat: no-repeat !important;
                            background-position: center;
                            width: 100px;
                            height: 100px;
                            right: 20%;
                            background-color: $color-white;
                            border: 1px solid $color-black;
                            border-radius: 0;
                        }
                    }
                    .product-item {
                        display: inline-block;
                        width: 100%;
                        margin: 0;
                        .product-item-info {
                            width: 100%;
                        }
                        .product-image-photo {
                            bottom: 0;
                            display: block;
                            height: auto;
                            left: 0;
                            margin: 0 auto;
                            max-width: 100%;
                            right: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}
.upsell-block{
    .product-items{
        justify-content: flex-end;
    }
    .product-item{
        margin-right:unset;
    }
}

@media (max-width: $screen__xl) {
    .product-info-main {
        padding-right: 0;
        .retail_price .tooltip .tooltiptext {
            right: 10%;
            left: unset;
            margin-left: -100px;
        }
    }
    .product.media .fotorama__wrap {
        .fotorama__nav-wrap {
            max-width: 20%;
        }
        .fotorama__stage {
            max-width: 80% !important;
        }
    }
    .product.info.detailed {
        .product.data.items {
            .data.item.content {
                .form.contact .fieldset .field {
                    margin-right: 10px;
                }
            }
        }

    }
}
@media (max-width: $screen__l) {
    .product-info-main {
        .retail_price {
            position: static;
            flex-wrap: wrap;
            //padding-bottom: 6px !important;
            .tooltip .tooltiptext {
                right: 0;
                margin-left: 0;
                top: 125%;
                left: unset;
            }
        }
    }
    .product.media .fotorama__wrap {
        flex-direction: column;
        .fotorama__nav-wrap {
            max-width: 100%;
            .fotorama__nav {
                width: 100% !important;
                height: fit-content !important;
                .fotorama__nav__shaft {
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        .fotorama__stage {
            max-width: 100% !important;
            .fotorama__stage__shaft {
                max-width: 100% !important;
            }
        }
    }
}
@media (max-width: 960px) {
    .product-info-main .product-options-wrapper .bss-ptd-table table tbody tr td.bss-qty-col input {
        max-width: 30px;
        padding: 0;
    }
}
@media (max-width: $screen__m - 1) {
    .product-info-main {
        max-width: 100%;
        width: 100% !important;
        margin-top: 0;
        h1.page-title, .product-info-stock-sku .attribute.sku {
            font-size: $font-size__base;
        }
        .retail_price {
            font-size: $font-size__s;
            .tooltip .tooltiptext {
                left: unset;
                right: 0;
            }
        }
        .old-price {
            .price-container {
                .price-wrapper .price {
                    font-size: $font-size__s !important;
                }
            }
        }
        .price-box {
            .price-label {
                font-size: $font-size__s !important;
            }
            .normal-price {
                .price-label {
                    font-size: $font-size__base !important;
                }
                .price-wrapper {
                    .price {
                        font-size: $font-size__base !important;
                    }
                }
            }
        }
        .contact_link {
            text-align: center;
        }
        .product-options-wrapper .bss-ptd-table table tbody tr td.bss-qty-col input {
            max-width: 64px;
        }
        .product-add-form{
            .availability{
                &.stock {
                    top: 16px;
                }
            }
        }
    }
    .product.media {
        @include fullpage-width;
        margin-top: 30px;
        .fotorama__wrap {
            .fotorama__stage {
                margin: auto;
            }
            .fotorama__nav-wrap .fotorama__nav {
                position: absolute;
                bottom: 4%;
                .fotorama__nav__shaft {
                    display: initial;
                }
            }
        }
        .fotorama__active {
            .fotorama__dot {
                background-color: $color-black;
                border-color: $color-black;
            }
        }
        .fotorama__dot {
            width: 8px;
            height: 8px;
            background-color: #bdbdbd;
            border-color: #bdbdbd;
        }
    }
    .product.info.detailed, .popup-content {
        margin-top: 44px;
        .product.data.items {
            position: relative;
            z-index: 1;
            #tab-label-details_tab {
                padding-left: $layout__width-xs-indent;
            }
            &:before, &:after {
                content: '';
                display: table;
            }
            .data.item.title, .item.title {
                float: left;
                width: auto;
                .switch {
                    padding-bottom: 0;
                    padding-top: 0;
                    position: relative;
                    z-index: 2;
                    display: block;
                }
            }
            .data.item.content, .item.content {
                float: right;
                margin-left: -100%;
                width: 100%;
                padding-left: $layout__width-xs-indent;
                //font-size: $font-size__s;
                .form.contact {
                    .fieldset {
                        width: 100%;
                        .field {
                            flex-basis: 100%;
                            &.privacy-check {
                                padding-left: 0;
                                flex-basis: 100%;
                            }
                            &.comment {
                                flex-basis: 100%;
                            }
                        }
                    }
                    .actions-toolbar {
                        width: 100%;
                    }
                }
            }
        }
    }
    .popup-sizeguide {
        .popup-content {
            margin-top: 0;
            width: 90%;
            .product.data.items {
                > .item.title {
                    .switch {
                        font-size: $font-size__base;
                        line-height: 19px;
                        height: 19px;
                        padding: 4.75px 19px 4.75px 19px;
                    }
                }
                > .item.content {
                    margin-top: 29.5px;
                    padding: 19px;
                    border: none;
                }
            }
        }
    }
    .block {
        &.related, &.upsell, &.crosssell {
            .block-content {
                .products {
                    .product-items {
                        display: block;
                        width: 100%;
                        &.owl-carousel{
                            .owl-item {
                                img{
                                    height: auto;
                                }
                            }

                            .owl-nav{
                                top: 30%;
                            }

                            .owl-prev {
                                background-size: 26px 26px !important;
                                background-repeat: no-repeat !important;
                                background-position: center;
                                width: 60px;
                                height: 60px;
                                left: 10%;
                                background-color: $color-white;
                                border: 1px solid $color-black;
                                border-radius: 0;
                            }
                            .owl-next {
                                background-size: 26px 26px !important;
                                background-repeat: no-repeat !important;
                                background-position: center;
                                width: 60px;
                                height: 60px;
                                right: 10%;
                                background-color: $color-white;
                                border: 1px solid $color-black;
                                border-radius: 0;
                            }
                        }
                        .product-item {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: $screen__s) {
    .product-info-main {
        .product-add-form {
            .size-guide_link {
                font-size: $font-size__xs;
            }
            .swatch-opt {
                font-size: $font-size__xs;
            }
        }
        .product-options-wrapper .bss-ptd-table table {
            font-size: $font-size__xs;
            tr > th {
                width: 20%;
                font-size: $font-size__xs !important;
                &:last-child {
                    width: 32%;
                }
            }
            tbody tr td.bss-qty-col {
                .btn-qty {
                    font-size: 14px;
                }
                input {
                    max-width: 35px;
                    font-size: $font-size__xs;
                }
            }
        }
    }
    .popup-sizeguide .popup-content {
        font-size: $font-size__xs;
        .product.data.items {
            > .item.title .switch {
                font-size: $font-size__xs;
            }
            > .item.content {
                font-size: $font-size__xs;
            }
        }
    }
}

